import React from "react";

const About: React.FC = () => {
  return (
    <div>
      <h1>About Page</h1>
      {/* Add your content here */}
    </div>
  );
};

export default About;
