import { MouseEventHandler } from "react";
import "./Button.css";

interface IButtonProps {
  title: string;
  onClick: MouseEventHandler<HTMLAnchorElement>;
  classNames?: string;
}

export default function Button({ title, onClick, classNames }: IButtonProps) {
  return (
    <a className={`Button ${classNames && classNames}`} onClick={onClick}>
      {title}
    </a>
  );
}
