import React from "react";
import logoFooter from "../../assets/footer-logo.png";
import logoFooter2x from "../../assets/footer-logo@2x.png";
import logoFooter3x from "../../assets/footer-logo@3x.png";
import logo from "../../assets/predeat-logo-white.png";
import logo2x from "../../assets/predeat-logo-white@2x.png";
import logo3x from "../../assets/predeat-logo-white@3x.png";
import { openInNewTab } from "../../utils";
import Button from "../Button";
import "./Footer.css";

const Footer: React.FC = () => {
  return (
    <div className="footer-wrapper">
      <footer className="footer-container">
        <div className="footer-top">
          <div className="footer-links-container">
            <img
              src={logo}
              className="PredEat-logo"
              alt="logo"
              srcSet={`${logo2x} 2x, ${logo3x} 3x`}
            />
            <Button
              classNames="Button-try white center"
              title="Download"
              onClick={openInNewTab}
            />
          </div>
          <ul className="ul-footer">
            <li>
              <a href="https://predeat.com/about-us.html" rel="_blank">
                About
              </a>
            </li>
            <li>
              <a href="https://predeat.com/terms-of-service.html" rel="_blank">
                Terms
              </a>
            </li>
            <li>
              <a href="https://predeat.com/privacy-policy.html" rel="_blank">
                Privacy
              </a>
            </li>
          </ul>
        </div>
        <img
          src={logoFooter}
          className="footer-logo"
          alt="logo"
          srcSet={`${logoFooter2x} 2x, ${logoFooter3x} 3x`}
        />
      </footer>
    </div>
  );
};

export default Footer;
