import "@material-design-icons/font";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "../App.css";
import imageBanner from "../assets/download-banner-app.svg";
import predEatPlan from "../assets/predeat-plan.png";
import Button from "../components/Button";
import Footer from "../components/Footer";
import Header from "../components/Header";
import data from "../data/recipes.json";
import { openInNewTab } from "../utils";
import "./Recipe.css";

interface Recipes {
  [name: string]: IRecipe;
}

interface IRecipe {
  name: string;
  ingredients: {
    [key: string]: Record<string, any>[];
  };
  cookingTime: string;
  portions: string;
  preparationTime: string;
  steps: {
    [key: string]: string[];
  };
}

const Recipe: React.FC = () => {
  const [recipe, setRecipe] = useState<IRecipe | null>(null);
  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();

  useEffect(() => {
    const { recipes } = data;
    const recipeName = query.get("name") as string;
    const language = query.get("language") as "en" | "es";
    if (recipeName === null || language === null) return;
    setRecipe((recipes[language] as unknown as Recipes)[recipeName]);
  }, [query]);

  return (
    <main className="App">
      <Header />
      <div className="app-banner-download-container">
        <div className="app-banner-download">
          <img src={imageBanner} alt="Predeat" />
          <div className="banner-label-container">
            <h3 className="banner-label">
              Save time, eat healthier, and cut down on food waste.
            </h3>
            <Button
              classNames="Button-try center download-banner"
              title="Download"
              onClick={openInNewTab}
            />
          </div>
        </div>
      </div>
      <div className="App-container app-recipe">
        {recipe === null || recipe?.name === undefined ? (
          <h3>Recipe not found</h3>
        ) : (
          <div className="recipe-wrapper">
            <div className="recipe-header">
              <h3 className="recipe-title">
                {/* {JSON.stringify(recipe)} */}
                {/* <span className="material-icons-sharp">arrow_back_ios</span>{" "} */}
                {recipe.name}
              </h3>
              <span>Prep time: {recipe.preparationTime}</span>
              <span>Cooking time: {recipe.cookingTime}</span>
            </div>
            <div className="recipe">
              <div className="recipe-container">
                {Object.keys(recipe.ingredients).map((ingredientGroup) => {
                  return (
                    <div key={ingredientGroup}>
                      <h4>{ingredientGroup}</h4>
                      <ul>
                        {recipe.ingredients[ingredientGroup].map(
                          (ingredient) => {
                            return (
                              <li key={ingredient.desc}>{ingredient.desc}</li>
                            );
                          }
                        )}
                      </ul>
                    </div>
                  );
                })}
              </div>
              <div className="recipe-container">
                {Object.keys(recipe.steps).map((stepsGroup) => {
                  return (
                    <div key={stepsGroup}>
                      <h4>{stepsGroup}</h4>
                      <ol>
                        {recipe.steps[stepsGroup].map((step) => {
                          return <li key={step}>{step}</li>;
                        })}
                      </ol>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
        <section className="PredEat-section slogan">
          <div className="left">
            <h2>Effortless meal prep for busy lives.</h2>
            <Button
              classNames="Button-try"
              title="Download"
              onClick={openInNewTab}
            />
          </div>
          <div className="right">
            <img
              src={predEatPlan}
              alt="PredEat Plan example"
              className="PredEat-plan"
            />
          </div>
        </section>
      </div>
      <Footer />
    </main>
  );
};

export default Recipe;
