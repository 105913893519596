import { ReactNode } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/PredEat-logo.png";
import logo2x from "../../assets/PredEat-logo@2x.png";
import logo3x from "../../assets/PredEat-logo@3x.png";
import "./Header.css";

interface IHeaderProps {
  children?: ReactNode;
}

export default function Header({ children }: IHeaderProps) {
  return (
    <div className="Header">
      <header className="Header-container">
        <Link to="/">
          <img
            src={logo}
            className="PredEat-logo"
            alt="logo"
            srcSet={`${logo2x} 2x, ${logo3x} 3x`}
          />
        </Link>
        {children && children}
      </header>
    </div>
  );
}
