import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import "./index.css";
import About from "./pages/About";
import Home from "./pages/Home";
import Recipe from "./pages/Recipe";

// Import the functions you need from the SDKs you need
import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC58_HjIQvdkBKN0QzP4uLdvC7za5ya7ys",
  authDomain: "predeat-17687.firebaseapp.com",
  databaseURL: "https://predeat-ro-recipes.firebaseio.com/",
  projectId: "predeat-17687",
  storageBucket: "predeat-17687.appspot.com",
  messagingSenderId: "304699846044",
  appId: "1:304699846044:web:f66d359a6708cb8ca86c59",
  measurementId: "G-T9BN23PPK8",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "recipe",
    element: <Recipe />,
  },
  {
    path: "about",
    element: <About />,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
