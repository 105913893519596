import React from "react";
import predEatPlan from "../assets/predeat-plan.png";
import step1 from "../assets/step-1.png";
import step12x from "../assets/step-1@2x.png";
import step13x from "../assets/step-1@3x.png";
import step2 from "../assets/step-2.png";
import step22x from "../assets/step-2@2x.png";
import step23x from "../assets/step-2@3x.png";
import step3 from "../assets/step-3.png";
import step32x from "../assets/step-3@2x.png";
import step33x from "../assets/step-3@3x.png";
import Button from "../components/Button";

import "../App.css";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { openInNewTab } from "../utils";

const Home: React.FC = () => {
  return (
    <main className="App">
      <Header>
        {/* <Link to="recipe">Recipes</Link> */}
        <Button title="Download" onClick={openInNewTab} />
      </Header>
      <div className="App-container">
        <div>
          <section className="PredEat-section slogan">
            <div className="left">
              <h2>You plan your life, PredEat your meals.</h2>
              <Button
                classNames="Button-try"
                title="Download"
                onClick={openInNewTab}
              />
            </div>
            <div className="right">
              <img
                src={predEatPlan}
                alt="PredEat Plan example"
                className="PredEat-plan"
              />
            </div>
          </section>
          <h2>PredEat in three simple steps:</h2>
          <section className="PredEat-section steps">
            <div className="left">
              <img
                src={step1}
                alt="Step 1"
                srcSet={`${step12x} 2x, ${step13x} 3x`}
              />
            </div>
            <div className="right">
              <h3>1. Pick your food preferences</h3>
              <ul>
                <li>Food likes: Mexican, Italian, American, other?</li>
                <li>What do you eat: all, vegetarian, vegan?</li>
                <li>How much time do you cook? </li>
                <li>Any allergies?</li>
              </ul>
            </div>
          </section>
          <section className="PredEat-section steps ai-surprise-you">
            <div className="left">
              <h3>2. Let AI surprise you</h3>
              <p>
                We use the latest AI technology to{" "}
                <strong>generate the recipes</strong> that you'll love!
              </p>
            </div>
            <div className="right">
              <img
                src={step2}
                alt="Step 1"
                srcSet={`${step22x} 2x, ${step23x} 3x`}
              />
            </div>
          </section>
          <section className="PredEat-section steps">
            <div className="left">
              <img
                src={step3}
                alt="Step 3"
                srcSet={`${step32x} 2x, ${step33x} 3x`}
              />
            </div>
            <div className="right">
              <h3>3. Get your shopping list</h3>
              <p>
                From the recipes that you've chosen, make
                <strong> automatically</strong> your shopping list!
              </p>
            </div>
          </section>
          <div className="Button-footer-wrapper">
            <Button
              classNames="Button-try center"
              title="Download"
              onClick={openInNewTab}
            />
          </div>
        </div>
      </div>
      <Footer />
    </main>
  );
};

export default Home;
