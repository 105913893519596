export const openInNewTab = () => {
  const newWindow = window.open(getOS(), "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};
// Function to get the visitor's operating system
export function getOS() {
  const userAgent = window.navigator.userAgent;

  // Checking for various operating systems
  if (userAgent.includes("Windows")) {
    return "https://play.google.com/store/apps/details?id=com.predeat.app";
  } else if (userAgent.includes("Macintosh") || userAgent.includes("Mac OS")) {
    return "https://apps.apple.com/app/predeat-smart-meal-planner/id6476419054";
  } else if (userAgent.includes("Linux")) {
    return "https://play.google.com/store/apps/details?id=com.predeat.app";
  } else if (userAgent.includes("Android")) {
    return "https://play.google.com/store/apps/details?id=com.predeat.app";
  } else if (userAgent.includes("iOS")) {
    return "https://apps.apple.com/app/predeat-smart-meal-planner/id6476419054";
  } else {
    return "Unknown";
  }
}
